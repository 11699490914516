.App {
  min-width: 100%;
  font-size: 3px ;
  font-family: 'Readex Pro', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[class*="div_container1"] {
  padding-top: 6vh;
  padding-bottom: 6vh;
  display: flex;
  flex-flow: row wrap;
  justify-content:center;
  background-size:cover;
  vertical-align: middle;
  align-items: center;
  text-align: center;
}
.div_3p { 
  display: flex;
  flex-flow: row wrap;
}
.div_container-gallery { 
  padding-top: 15vw;
  padding-bottom: 15vw;
  width: 100%;
  display:inline-block;
  text-align: center;
  background-color: rgb(0, 0, 0); 
  text-shadow:none;
  font-weight: 200;
  font-size: 4vw;
  letter-spacing: 2.5vmin;
  color: #ffffff;
}
.div_ending { 
  padding-top: 8vw;
  padding-bottom: 6vw;
  width: 100%;
  display:inline-block;
  text-align: center;
  background-color: rgb(198, 198, 198); 
  text-shadow:none;
  font-weight: 300;
  font-size: 3.5vw;
  letter-spacing: .4vmin;
  color: #000000;
}

.div_container1-banner { 
  width: 100%;
  display: block;
  position: relative;
  background-color: rgb(208, 26, 15); 
  background-image: url('Banner_bg.jpg');
  background-size: auto 95vh;
  background-position: right bottom;
  background-repeat: no-repeat;
  min-height: 80vh;
  height: 80vh;
  
}
.div_inside1-banner{
  position:absolute;
  text-align: center;
  left: 10vw;;
  bottom: 10vw;
}

.div_3t-1 { 
  padding-top: 6vh;
  padding-bottom: 6vh;
  flex: 1 1 18%;
  background-color: rgb(232, 144, 72); 
  background-image: url('Witch01_bg.jpg');
  background-position: center bottom;
  background-size: cover;
}
.div_3t-2 { 
  padding-top: 6vh;
  padding-bottom: 6vh;
  flex: 1 1 18%;
  background-color: rgb(39, 31, 57); 
  background-image: url('Seastar_bg1.jpg');
  background-position: center top;
  background-size: cover;
}
.div_3t-3 { 
  flex: 1 1 18%;
  background-color: rgb(0, 0, 0); 
  background-position: center top;
}
.div_3t-4 { 
  flex: 1 1 18%;
  background-color: rgb(252, 111, 2);
  background-image: url('Spark_bg.jpg'); 
  background-position: center center;
  background-size: cover;
}
.div_3t-5 { 
  flex: 1 1 18%;
  background-color: rgb(41, 28, 60);
  background-image: url('Space_bg1.jpg'); 
  background-position: center center;
  background-size: cover;
}
.div_container1-3 { 
  display: table;
  width: 100%;
  background-position: right bottom;
  background-attachment: fixed;
  background-color:rgb(1, 195, 249);
  background-image: url('Sky1.jpg');
  background-size: cover;
}
.div_container1-7 { 
  display: table;
  width: 100%;
  background-image: url('coco_bg.jpg');
  background-position: right top;
  background-color: rgb(49, 208, 118); 
  background-size: cover;
  background-attachment: fixed;
}
.div_container1-8 { 
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color:rgb(255, 0, 68);
  background-image: url('pythonx_bg.jpg');
  background-position: right top;
  
  background-size: cover;
}

.flex2div{
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.flex2div_1{
  vertical-align: middle;
  //flex: 1 1 18%;
  height: 100%;
  //min-width: 500px;
}
.flex2div_2{
  vertical-align: middle;
  //flex: 1 1 18%;
  min-height: 100%;
  height: 100%;
  //min-width: 500px;

}

[class*="div1_1"]{
  vertical-align: middle;
  text-align: center;
  padding-left: 2vw;
  padding-right: 2vw;
}
.div1_1-1 {
  text-align: center;
  min-width: 50%;
}
.div1_1-2 {
  text-align: right;
  min-width: 50%;
}
.div1_1-3 {
  text-align: center;
}

[class*="div1_2"]{ 
  height: 100%;
  vertical-align: middle;
  text-align: left;
  justify-content: left;
  padding-left: 20px;
  font-size: .4vw;
}
.div1_2-1 {  
  text-align: left;
  justify-content: left;
  align-items:left;
  color:rgb(255, 255, 255); 
  padding:0px; 
  flex: 1 1 18%;
}
.div1_2-2 {  
  color:rgb(0, 0, 0); 
  text-align: left;
  justify-content: left;
  align-items:left;
  padding:0px; 
  flex: 1 1 18%;
}
.div1_2-3 { 
  color:rgb(0, 0, 0); 
  text-align: center;
  padding:0px; 
  flex: 1 1 18%;
}

.img1 {
  height: 75vh;
  width: auto;
  border-radius: 1vw;
}
.img2 {
  height: 60vh;
  width: auto;
  border-radius: 1vw;
}
.img_sq1 {
  height: 60vh;
  width: auto;
  border-radius: 1vw;
}
.img5 {
  height: 50vh;
  width: auto;
  border-radius: 1vw;
}


.video_land1{
  width: min(90vw,120vh);
  height: auto;
  border-radius: 1vw;
}
.video_sq1{
  width: min(40vw,120vh);
  min-width: 700px;
  height: auto;
  border-radius: 1vw;
}

[class*="font1_1"] {
  text-shadow: 0px 0px 3vh #ffffff;
}
.font_banner1{
  text-shadow:none;
  font-weight: 300;
  font-size: 20vw;
  line-height: 10px;
  color: #ffffff;
}
.font_banner2{
  text-shadow:none;
  font-weight: 300;
  font-size: 9vmin;
  color: #ffffff;
}
.font_banner3 {
  text-shadow:none;
  font-weight: 300;
  font-size: 3vmin;
  letter-spacing: 1.2vmin;
  color: #ffffff;
}


.font1_1-1{
  font-weight: 300;
  font-size: min(6vh,5vw);
  line-height: min(11vh,9vw);
  color: #000000;
}
.font1_1-2{
  font-weight: 300;
  font-size: min(6vh,5vw);
  line-height: min(11vh,9vw);
  color: #ffffff;
}
.font1_1-3{
  font-weight: 300;
  font-size: min(11vh,6vw);
  line-height: min(11vh,9vw);
  text-shadow: none;
  color: #53506a;
}
.font1_1-7{
  font-weight: 300;
  font-size: min(12vh,8vw);
  line-height: min(12vh,8vw);
  color: #000000;
}
.font1_1-8 {
  text-shadow: none;
  font-weight: 300;
  font-size: min(10vh,8vw);
  line-height: min(10vh,8vw);
  text-shadow: 10px 10px 2vh #000000;
  color: #ffffff;
}

[class*="myButton"] {
  vertical-align:middle;
  font-family: 'Readex Pro', sans-serif;
	border-radius:20vw;
	display: inline-block;
	cursor:pointer;
	color:#000000;
	font-size:2vh;
  line-height: 2vh;
  font-weight: 400;
  text-decoration:none;
	padding: 1.3vh 2vh;
}
.myButton-1{
  color: white;
	background-color:#43203e;
}
.myButton-2{
	background-color:#ffffff;
}
.myButton-3{
  color: white;
	background-color:#000000;
}

.myButton:hover {
	background-color:#466b95;
}

.myButton:active {
  background-color:#72e098;
	position:relative;
	top:1px;
}
        
.FND_logo {
  height: 1.7vh;
}

.FND_opensea{
  height: 4vh;
}

.brOut{
  display: block;
}


@media screen and (max-width: 1800px) {
  .div_inside1-banner{
    position:absolute;
    text-align: left;
    bottom: 1vmax;
    left: 10vmin;
  }
}

@media screen and (max-width: 1200px) {
  .div1_2-1 {  
    text-align: center;
  }
  .div1_2-2 { 
    text-align: center; 
  }
  .brOut{
    display: none;
  }

  .video_sq1{
    width: 90vw;
  }
}


@media screen and (max-width: 720px) {
  
  [class*="div_container1"] {
    display: table;
    width: 100%;
    justify-content:center;
    padding: 0px;
    background-position: center bottom;
  }
  
  .div_inside1-banner{
    position:absolute;
    text-align: center;
    bottom: 3vmax;
    left: 10vmin;
  }

  [class*="div1_1"] {
    width: 100%;
    padding: 0px;
    padding-top: 20px;
    text-align: center;
  }
  .div1_1-1{background-position: center bottom; }
  .div1_1-2{background-position: center top; }
  
 
  [class*="div1_2"] {
    display:block;
    width: 100%;
    text-align: center;
    padding: 0px;
    padding-top: 20px;
    font-size: 2px;
  }
  
  .img1 {
    width: 90vw;
    height: auto;
    border-radius: 1vw;
    border-radius: 3vw;
  }
  .img_sq1 {
    width: 90vw;
    height: auto;
    border-radius: 2vw;
  }

  .video_land1{
    height:auto;
    max-height: 60vmin;
    max-width: 95vw;
    width: auto;
    border-radius: 2vw;
  }
  .video_sq1{
    width: min(40vw,120vh);
    min-width: 80vw;
    height: auto;
    border-radius: 2vw;
  }

  [class*="font1_1"] {
    font-size: 60px;
    line-height: 55px;
  }
  .font1_1-1 { }
  .font1_1-2 { font-weight: 400; font-size: 45px; }
  .font1_1-3 { font-weight: 600; font-size: 40px; letter-spacing: -1px; }
  .font1_1-8 {
    text-shadow: none;
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
  }

  .font_banner1{
    text-shadow:none;
    font-weight: 300;
    font-size: 40vw;
    line-height: 10px;
    color: #ffffff;
  }
  .font_banner3{
    text-shadow:none;
    font-weight: 600;
    font-size: 3vmin;
    letter-spacing: 1vmin;
    color: #ffffff;
  }

  
  [class*="myButton-"] {
    text-decoration:none;
    font-weight: 600;
    font-size: 12px;
    padding:12px 15px;
  }

  .FND_logo {
    height: 12px;
  }
}